import React, { useEffect } from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import { useIntl } from 'gatsby-plugin-intl'
import { useTranslation } from 'react-i18next'
import logo from '../images/Gasell-2020-logga-3.png'
import isoLogo from '../images/isoCert.png'
import 'material-icons/iconfont/material-icons.css'

//STYLES
import '../styles/index.css'

//ICONS
import FilterDramaIcon from '@material-ui/icons/FilterDrama'
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone'
import LaptopMacIcon from '@material-ui/icons/LaptopMac'
import CompareArrowsIcon from '@material-ui/icons/CompareArrows'

//COMPONENTS
import Header from '../components/header'
import Footer from '../components/footer'
import IndexCards from '../components/indexCard'
import Layout from './layout'

const Home = ({ data }) => {
  const intl = useIntl()
  const locale = intl.locale !== 'sv' ? `/${intl.locale}` : ''
  let md = data.allMarkdownRemark.edges[0].node.frontmatter
  let jsonEn = data.allIntlJson.edges[0] //Engelska arrayen
  let jsonSv = data.allIntlJson.edges[1] //Svenska arrayen
  const { t, i18n } = useTranslation()

  let cloudCardsEn = jsonEn.node.index.cloudArray.map((item, i) => (
    <IndexCards
      key={i}
      path={t(`indexCardCloudPath.${i}`, { returnObjects: true })}
      icon={item.icon}
      specialize={t(`indexCardCloudTitle.${i}`, { returnObjects: true })}
      content={t(`indexCardCloudText.${i}`, { returnObjects: true })}
    />
  ))

  let cloudCardsSv = jsonSv.node.index.cloudArray.map((item, i) => (
    <IndexCards
      key={i}
      path={t(`indexCardCloudPath.${i}`, { returnObjects: true })}
      icon={item.icon}
      specialize={t(`indexCardCloudTitle.${i}`, { returnObjects: true })}
      content={t(`indexCardCloudText.${i}`, { returnObjects: true })}
    />
  ))

  let developmentCardsSv = jsonSv.node.index.developmentArray.map((item, i) => (
    <IndexCards
      key={i}
      path={t(`indexCardDevPath.${i}`, { returnObjects: true })}
      icon={item.icon}
      specialize={t(`indexCardDevTitle.${i}`, { returnObjects: true })}
      content={t(`indexCardDevText.${i}`, { returnObjects: true })}
    />
  ))

  let developmentCardsEn = jsonEn.node.index.developmentArray.map((item, i) => (
    <IndexCards
      key={i}
      path={t(`indexCardDevPath.${i}`, { returnObjects: true })}
      icon={item.icon}
      specialize={t(`indexCardDevTitle.${i}`, { returnObjects: true })}
      content={t(`indexCardDevText.${i}`, { returnObjects: true })}
    />
  ))
  let collaborationCompanies = md.collaborationCompaniesArray.map(
    (company, i) => (
      <a href={company.path} key={i} aria-label={company.name}>
        <Img
          style={{ display: 'block', margin: 'auto' }}
          key={i}
          fixed={company.company.childImageSharp.fixed}
        />{' '}
      </a>
    )
  )

  useEffect(() => {
    if (locale === '') {
      i18n.changeLanguage('sv')
    }
    if (locale === '/en') {
      i18n.changeLanguage('en')
    }
  }, [locale, i18n])

  return (
    <Layout>
      <div id="home">
        <Img
          className="header-size"
          fluid={data.indexImage.childImageSharp.fluid}
        ></Img>
        <div
          style={{
            background:
              'linear-gradient(180deg,rgba(22,123,250,.61) 20%,#167bfa 80%)',
          }}
          className="header-img"
          alt="Index header image"
        >
          <Header />
          <div className="selling-text">
            <h1>{intl.formatMessage({ id: 'index.title' })}</h1>
            <p className="title-text">
              {intl.formatMessage({ id: 'index.titleText' })}
            </p>
            <div className="buttons">
              <Link
                className="header-btn"
                onClick={() => console.log('clicked')}
                to={`${locale}/contact`}
              >
                <button href="#" className="msg-btn">
                  {intl.formatMessage({ id: 'index.send-msg' })}
                </button>
              </Link>
              <Link
                className="header-btn"
                onClick={() => console.log('clicked')}
                to={`${locale}/digital-innovation`}
              >
                <button className="innovation-btn">
                  {intl.formatMessage({ id: 'index.digital-inno-btn' })}
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="divider"></div>
        <main>
          <div className="work-areas">
            <p className="work-areas-item">
              <FilterDramaIcon className="work-area-icons" />
              {md.cloud}
            </p>
            <p className="work-areas-item">
              <PhoneIphoneIcon className="work-area-icons" />
              {md.native}
            </p>
            <p className="work-areas-item">
              <LaptopMacIcon className="work-area-icons" />
              {md.web}
            </p>
            <p className="work-areas-item">
              <CompareArrowsIcon className="work-area-icons" />
              {md.devops}
            </p>
          </div>
          <div className="digital-innovation-grid">
            <div className="innovation-text">
              <p className="blue-text">
                {intl.formatMessage({ id: 'index.introduction' })}
              </p>
              <h2 className="section-title">
                {intl.formatMessage({ id: 'index.digitalInno' })}
              </h2>
              <p className="section-text">
                {intl.formatMessage({ id: 'index.digitalInnoText' })}
              </p>
              <Link to="/digital-innovation">
                <button className="digital-innovation-btn">
                  {intl.formatMessage({ id: 'index.digital-inno-btn' })}
                </button>
              </Link>
            </div>
            <div className="innovation-img">
              <Img
                className="working-image gatsby-image"
                fluid={data.digitalInnoImage.childImageSharp.fluid}
                alt="workingspace"
              />
              <Img
                className="apple-image gatsby-image"
                fluid={data.appleImage.childImageSharp.fluid}
                alt="workingspace"
              />
            </div>
          </div>

          <div className="cloud-section">
            <div className="cloud-section-text test">
              <p className="blue-text">
                {intl.formatMessage({ id: 'index.whatwedo' })}
              </p>
              <h2 className="section-title">
                {intl.formatMessage({ id: 'index.cloudH2' })}
              </h2>
              <p className="section-text">
                {intl.formatMessage({ id: 'index.cloudText' })}
              </p>
            </div>
            <div className="cloud-section-cards test">
              {intl.locale === 'sv' ? cloudCardsSv : cloudCardsEn}
            </div>
          </div>
          <div className="development-section">
            <div className="development-section-text">
              <p className="blue-text">
                {intl.formatMessage({ id: 'index.whatwedo' })}
              </p>
              <h2 className="section-title">
                {intl.formatMessage({ id: 'index.development' })}
              </h2>
              <p className="section-text">
                {intl.formatMessage({ id: 'index.developmentText' })}
              </p>
            </div>
            <div className="development-section-cards">
              {intl.locale === 'sv' ? developmentCardsSv : developmentCardsEn}
            </div>
          </div>
          <div className="work-with-us-grid">
            <div className="text-section">
              <div style={{ maxWidth: '650px', margin: '0 auto' }}>
                <h4 className="work-with-us-h4">
                  {intl.formatMessage({ id: 'index.work-with-us' })}
                </h4>
                <h3 className="work-with-us-title">
                  {intl.formatMessage({ id: 'index.need-help' })}
                </h3>
                <p className="work-with-us-text">
                  {intl.formatMessage({ id: 'index.work-with-us-text' })}
                </p>
                <Link to={`${locale}/contact`}>
                  <button className="work-with-us-btn">
                    {intl.formatMessage({ id: 'index.send-msg' })}
                  </button>
                </Link>
              </div>
            </div>
            <div className="image-section">
              <Img
                className="two-guys-working"
                fluid={data.workWithUsImage.childImageSharp.fluid}
                alt="two guys working"
              />
            </div>
          </div>
          <div className="recension">
            <h3 className="recension-title">
              {' '}
              {intl.formatMessage({ id: 'index.recension' })}
            </h3>
            <p className="recension-text">
              {intl.formatMessage({ id: 'index.recensionText' })}
            </p>
          </div>
          <div className="gasell-foretag-container">
            <div className="gasell-foretag">
              <h2>{t('isoHeader')}</h2>
              <h3>{t('isoText')}</h3>
            </div>
            <div>
              {' '}
              <img alt="gasell" className="gasell-logo" src={isoLogo}></img>
            </div>
          </div>
          <div className="collaborations">
            <div className="collaboration-text">
              <p className="blue-text">
                {intl.formatMessage({ id: 'index.collaboration' })}
              </p>
              <h2 className="section-title">
                {intl.formatMessage({ id: 'index.collaborationTitle' })}
              </h2>
              <p className="section-text">
                {intl.formatMessage({ id: 'index.collaborationText' })}
              </p>
            </div>
            <div className="collaboration-companies">
              {collaborationCompanies}
            </div>
          </div>
          <div className="gasell-foretag-container">
            <div className="gasell-foretag">
              <h2>{t('gasellHeader')}</h2>
              <h3>{t('gasellText')}</h3>
            </div>
            <div>
              {' '}
              <img alt="gasell" className="gasell-logo" src={logo}></img>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    allMarkdownRemark(filter: { frontmatter: { key: { eq: "index" } } }) {
      edges {
        node {
          frontmatter {
            title
            titleText
            introduction
            web
            devops
            cloud
            native
            digitalInnovation
            digitalInnovationText
            whatwedo
            cloudH2
            cloudText
            development
            developmentText
            workWithUs
            workWithUsTitle
            workWithUsText
            recension
            recensionText
            collaboration
            collaborationTitle
            collaborationText
            cloudArray {
              content
              specialize
              path
              icon
            }
            developmentArray {
              content
              specialize
              path
              className
              icon
            }
            collaborationCompaniesArray {
              path
              name
              company {
                childImageSharp {
                  fixed(height: 34) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
    }
    allIntlJson {
      edges {
        node {
          index {
            developmentArray {
              className
              content
              path
              specialize
              readMore
              icon
            }
            cloudArray {
              content
              path
              specialize
              readMore
              icon
            }
          }
        }
      }
    }
    appleImage: file(
      relativePath: { eq: "julian-o-hayon-Bs-zngH79Ds-unsplash-2-scaled.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    digitalInnoImage: file(
      relativePath: { eq: "digital-innovation-compressed.jpeg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    indexImage: file(relativePath: { eq: "index-image.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    workWithUsImage: file(relativePath: { eq: "work-with-us-image-min.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default Home
